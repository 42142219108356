import React from 'react';
import { CookieBanner } from '@palmabit/react-cookie-law';
import { useContext } from 'react';
import AppContext from './AppContext';
//import {useContent} from '../hooks/content-context';

function CookieConsent(props) {

    const myContext = useContext(AppContext);
    //const {state: {contentCookieConsent}} = useContent();
    return (
        <React.Fragment>
             <CookieBanner
      message="Diese Seite verwendet Cookies"
      acceptButtonText="Alle Akzeptieren"
      managePreferencesButtonText="Auswahl ändern"
      showDeclineButton={true}
      declineButtonText = "Ablehnen"
      privacyPolicyLinkText = "Datenschutz"
      policyLink="./impressum"
      wholeDomain={true}
      onAccept = {() => {}}
      preferencesDefaultChecked={true}
      statisticsDefaultChecked={true}
      marketingDefaultChecked={true}
      onAcceptPreferences = {() => {}}
      onAcceptStatistics = {() => {myContext.setcookie3value(true)}}
      onDeclineStatistics = {() => {myContext.setcookie3value(false)}}
      onAcceptMarketing = {() => {}}
      styles={{
        dialog: { position:"fixed",zIndex:1000,left:0,right:0,padding:"10px",bottom:0, backgroundColor:"rgba(248,247,247,.8)"},
        optionLabel:{color:'#333333', marginLeft:'20px'}
      }}
    />
        </React.Fragment>
    );
}

export default CookieConsent;