import './App.css';
import React, {useState,useEffect} from "react";
import {BrowserRouter as Router, Routes, Route } from "react-router-dom"; //useLocation

import Home from "./home";
import Neurocoaching from './neurocoaching';
import Impressum from './impressum';
import CookieConsent from "./CookieConsent"
import AppContext from './AppContext';

/*import PostDetails from "./components/PostDetails";

import { GlobalStyles } from 'twin.macro'; // new

import GlobalFonts from './fonts/fonts';

import {LanguageProvider} from './hooks/language-context';
import {ContentProvider} from './hooks/content-context';*/

function App() {

  const [cookie1value, setcookie1value] = useState(false);
  const [cookie2value, setcookie2value] = useState(false);
  const [cookie3value, setcookie3value] = useState(false);
  const [cookie4value, setcookie4value] = useState(false);

  const userSettings = {
    cookie1: cookie1value,
    cookie2: cookie2value,
    cookie3: cookie3value,
    cookie4: cookie4value,
    setcookie1value,
    setcookie2value,
    setcookie3value,
    setcookie4value,
  };

  return (

    <div>
      <AppContext.Provider value={userSettings}>
        <CookieConsent/>
        <Router>
            <Routes>
              <Route exact path="/" element={<Home/>}/>
              <Route exact path="/neurocoaching" element={<Neurocoaching/>}/>
              <Route exact path="/impressum" element={<Impressum/>}/>
            </Routes>
        </Router>
        
      </AppContext.Provider>
  </div>
  )
}

export default App;


