import logo from './logo.svg';
import svgBolt from './images/bolt-solid.svg';
import svgCard from './images/card-regular.svg';
import svgCompass from './images/compass.svg';
import svgGem from './images/gem-regular.svg';
import svgGlasses from './images/glasses-solid.svg';
import svgSpider from './images/spider-solid.svg';
import svgQuote from './images/iconquote.svg'
import jpgWald from './images/wald.jpg';
import jpgNeuronen from './images/neuronen.jpg';
import jpgBrain from './images/brain_alt.jpg';
import jpgChange from './images/change.jpg';
import jpgPortrait from './images/portrait.jpg';
import jpgBaum from './images/baum.png';
import jpgBlue from './images/bg_01.jpg';
import jpgPraxis2 from './images/praxis_wennigsen_02.jpg';
import jpgSupervision from './images/supervision.jpg';
import bgQuote01 from './images/splotch02.jpg';
import bgQuote02 from './images/splotch01.jpg';
import bgQuote03 from './images/splotch03.jpg';
import svgLogoBlack from './images/logo_black.svg';
import svgLogoWhite from './images/logo_white.svg';
import lottieLinie from './images/struktur.json';
import Contactform from './contactform';

import Footer from './footer';
import Lottie from 'react-lottie';
import { Parallax, Background} from 'react-parallax';
import Menu from './menu';
import {HL,Section,SectionBody,Headline,Blog,BlogPost, Hero,HeroTextSpan,H1,P,CTA,Button,HeroImageSpan,Benefits, Benefit, BenefitIcon, Icon10, BenefitText, H2,P2, QuoteBg,QuoteWrapper,QuoteText,QuoteName,QuoteTitel,QuoteSeperator}  from "./home_style";
import { Container } from 'postcss';

function Home() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieLinie,
    renderer: 'canvas',

    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  
  return (
    
<div>

  <Menu/>

  <Parallax bgImage={jpgBlue} strength={300} bgImageStyle={{ "object-fit": 'cover'}}>

    <Background >
      <div class="w-screen sm:w-screen flex flex-wrap  mx-auto justify-center xl:justify-end opacity-50 h-screen self-center pt-64 sm:pt-0">
        <Lottie options={defaultOptions} />
      </div>
    </Background>

    <Section>
      <div class="h-28"></div>
        <Hero>
          <HeroTextSpan $Right>
            <H1>bewegen, entwickeln <br/>verbinden & ankommen</H1>
              <P> </P>
            <CTA>
              
            </CTA>
          </HeroTextSpan>
          <HeroImageSpan class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          </HeroImageSpan>
        </Hero>
    </Section>

  </Parallax>

  <Section $greyBg>
  
  <SectionBody>

    <BlogPost class="text-justify w-full mb-12">
        <H1 $Center>Blockaden <HL>lösen</HL> und Ressourcen <HL>stärken</HL></H1>
        <P $Blog>Blockaden in Form von Krisen, Ängsten und Stress werden als unangenehm und herausfordernd erlebt. Sie trennen uns von unserer Kreativität, unseren Ressourcen und vielem was uns sonst ausmacht. Sie nehmen uns die Orientierung und lassen uns in manchen Situationen ratlos zurück. Am Besten es gäbe sie nicht! Oder? Doch so unangenehm diese Gefühle auch sind: Oft sind sie die treibende Kraft die zur Veränderung und Entwicklung bewegt!</P>
        <P $Blog>Mit meinem Coaching unterstütze ich Sie Blockaden zu lösen und Ressourcen zu stärken. Ihre Verbindung zu sich selbst, eine persönliche Entwicklung und inneres Wachstum ebnen Ihren weiteren Weg.</P>
    </BlogPost>

    <QuoteBg style={{backgroundImage: 'url('+bgQuote02+')',backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
      <QuoteWrapper >
        <QuoteText>»Die wesentliche Voraussetzung für Dein Glück ist die Bereitschaft der zu sein, der Du wirklich bist.«</QuoteText>
          <QuoteSeperator ></QuoteSeperator><QuoteName>Erasmus von Rotterdam</QuoteName><QuoteTitel>Theologe</QuoteTitel>
      </QuoteWrapper>
    </QuoteBg>

  </SectionBody>
</Section>
<Section>
  <SectionBody>

    <BlogPost class="text-justify w-full mb-12">
        <H1 $Center>Mein <HL>Verständnis</HL> von Coaching</H1>
        <P $Blog>Ein Tier läuft im Zoo in seinem Gehege entlang der Gitterstäbe auf und ab. Es ist dort geboren worden und hat in seinem Leben nie die Wildnis gesehen. Instinktiv weiß es, dass es mehr gibt als nur das Gehege: Seine Freiheit in der es das nutzen kann, was in ihm angelegt ist.</P>
        <P $Blog>Kennen Sie dieses Gefühl?</P>
        <P $Blog>Coaching ist für mich Hilfe zur Selbsthilfe: Der Experte für Ihre Bedürfnisse und Ziele sind Sie. In Ihnen ist alles, was Sie für Ihren Prozess brauchen, auch wenn Ihnen das nicht immer bewusst ist.</P>
    </BlogPost>

  </SectionBody>
</Section>

<Section $greyBg>
  <SectionBody>

    <BlogPost class="text-justify w-full mb-12">
      <H1 $Center>Effektiv, <HL>schnell</HL>, nachhaltig!</H1>
        <P $Blog>Mit meiner Arbeit als Coach unterstütze ich Sie Lösungen zu finden. Es geht darum sich Ihre Ressourcen bewusst zu machen, sie zu aktivieren und zu stärken. Ich verstehe es als meine Aufgabe Ihren individuellen Weg zum Ziel, schnell und nachhaltig zu begleiten.</P>
        <P $Blog>Die Werte Achtsamkeit, Mitgefühl, Kreativität und Humor sind für mich die Grundlage meiner Arbeit.</P>
        <P $Blog>Auf Basis aktueller Erkenntnisse der Hirnforschung, hypno-/systemischer Ansätze und altem Wissen indigener Völker nutze ich Methoden, die das Potential haben, kognitive und emotionale Blockaden zu lösen um den Weg zu Ihren Ressourcen frei zu machen.</P>
        <P $Blog>Ich lade Sie ein, sich beim Lesen meiner Seite einen ersten Eindruck von meinem Coachingangebot zu machen und freue mich darauf Sie persönlich kennen zu lernen! In einem kostenfreien Erstgespräch am Telefon finden Sie und ich heraus was Sie zu mir führt und wobei ich Sie unterstützen kann.</P>
    </BlogPost>

    <QuoteBg style={{backgroundImage: 'url('+bgQuote01+')',backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
      <QuoteWrapper >
        <QuoteText>»Man kann einen Menschen nichts lehren. Man kann ihm nur helfen es in sich selbst zu entdecken.«</QuoteText>
          <QuoteSeperator ></QuoteSeperator><QuoteName>Galileo Galilei</QuoteName><QuoteTitel></QuoteTitel>
      </QuoteWrapper>
    </QuoteBg>

  </SectionBody>
</Section>

<Section>
  <SectionBody>

    <Headline>
      <H1 $Center>Mögliche Themen</H1>
     
    </Headline>

    <Benefits>
      <Benefit>
        <BenefitIcon><Icon10 src={svgGem}/></BenefitIcon>
        <BenefitText>
          <H2>Ressourcen</H2>
            <P2>Sie wollen auf Ihre Ressourcen zugreifen können oder wollen für bestimmte Situationen Ihre Ressourcen verstärken um einen Vortrag zu halten, eine Prüfung abzulegen oder sonstige Herausforderungen zu meistern?</P2>
        </BenefitText>
      </Benefit>
      <Benefit>
        <BenefitIcon><Icon10 src={svgCompass}/></BenefitIcon>
        <BenefitText>
          <H2>Orientierung</H2>
            <P2>Sie wollen Unterstützung bei Ihrer persönlichen Standortbestimmung in Ihrem Leben oder einem Lebensbereich, um Klarheit über Ihre Ziele und die nächsten Schritte auf Ihrem Weg zu bekommen?</P2>
        </BenefitText>
      </Benefit>
      <Benefit>
        <BenefitIcon><Icon10 src={svgGlasses}/></BenefitIcon>
        <BenefitText>
          <H2>Glaubenssätze</H2>
            <P2>Sie möchten an Ihren Überzeugungen arbeiten oder diese positiv verändern, um in Ihnen neue Möglichkeiten zu erschließen, von denen Sie eigentlich glauben, dass “das nicht funktioniert“ oder Sie “nicht gut genug sind“?</P2>
        </BenefitText>
      </Benefit>
      <Benefit>
        <BenefitIcon><Icon10 src={svgCard}/></BenefitIcon>
        <BenefitText>
          <H2>Gewohnheiten</H2>
            <P2>Sie möchten unvorteilhafte oder lästige Gewohnheiten verändern oder ablegen, die mittlerweile überholt sind und Sie auf lästige Weise weiterhin begleiten und stören?</P2>
        </BenefitText>
      </Benefit>
      <Benefit>
        <BenefitIcon><Icon10 src={svgSpider}/></BenefitIcon>
        <BenefitText>
          <H2>Ängste</H2>
            <P2>Sie würden gerne von Ängsten und den damit verbundenen Emotionen befreit sein oder diesen Gefühlen die Intensität nehmen? Unangenehme Gefühle vor bestimmten Situationen oder Objekten, wie  z.B. Spinnen, möchten sie gerne auflösen.</P2>
        </BenefitText>
      </Benefit>
      <Benefit>
        <BenefitIcon><Icon10 src={svgBolt}/></BenefitIcon>
        <BenefitText>
          <H2>Stress</H2>
            <P2>In Ihrem Leben ist der Stress belastend geworden und beansprucht Sie sogar in Situationen, die eigentlich völlig stressfrei und entspannt sein könnten? Ihre Gedanken kreisen um das stressbesetzte Thema und Sie kommen gar nicht mehr richtig zur Ruhe?</P2>
        </BenefitText>
      </Benefit>    

    </Benefits>

  </SectionBody>
</Section>


<Parallax bgImage={jpgPraxis2} strength={400} bgImageStyle={{ "object-fit": 'cover'}}>
      <div class="h-screen/2">
      </div>
</Parallax>

<Section>
  <SectionBody>
    <BlogPost class="text-justify w-full mb-12">
      <H1 $Center>Für wen und wann kann ein <HL>Coaching</HL><br/> hilfreich sein?</H1>
        <P $Blog>Wenn Sie sich weiterentwickeln wollen, etwas in Ihrem Leben ändern, Klarheit gewinnen möchten oder sich in einer Krise befinden - dann kann ein Coaching sinnvoll sein. Ob es um berufliche Neuorientierung, lästige Angewohnheiten, ein Gefühl der Überforderung oder etwas anderes geht: Die innere Motivation einen Schritt zu machen und sich zu bewegen, ist die beste Voraussetzung um in kurzer Zeit große Schritte zu machen.</P>
    </BlogPost>
    
    <QuoteBg style={{backgroundImage: 'url('+bgQuote03+')',backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
      <QuoteWrapper >
        <QuoteText>»Probleme können nicht mit demselben Bewusstsein gelöst werden in dem sie entstanden sind.«</QuoteText>
          <QuoteSeperator ></QuoteSeperator><QuoteName>Albert Einstein</QuoteName><QuoteTitel>Physiker</QuoteTitel>
      </QuoteWrapper>
    </QuoteBg>

  </SectionBody>
</Section>

<Section $greyBg>
  <SectionBody>
    <BlogPost class="text-justify w-full mb-12">
      <H1 $Center>Krise und <HL>Chance</HL></H1>
        <P $Blog>Menschen fallen Veränderungen von Natur aus nicht leicht. Wir haben ein großes Bedürfnis nach Kontinuität. Sie gibt uns Sicherheit. Aus den Zeiten, in denen wir vom Schicksal herausgefordert werden, können (r)evolutionäre Veränderungen erwachsen. Auch wenn es nicht leicht fällt: Die bewusste Auseinandersetzung mit sich und dem eigenen Leben in Krisenzeiten lohnt sich! Wussten Sie eigentlich: In China wird für Krise und Chance dasselbe Schriftzeichen verwendet.</P>
    </BlogPost>

    <QuoteBg style={{backgroundImage: 'url('+bgQuote01+')',backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
      <QuoteWrapper >
        <QuoteText>»Die kälteste Zeit der Nacht ist die kurz vor der Morgendämmerung.«</QuoteText>
          
      </QuoteWrapper>
    </QuoteBg>

    </SectionBody>
</Section>

<Parallax bgImage={jpgNeuronen} strength={300} bgImageStyle={{ "object-fit": 'cover'}}>
      <div class="h-screen/2"></div>
</Parallax>

  <Section $greyBg>
    <Hero $Right>
      <HeroTextSpan $Right>
        <H1>Neuro-Coaching</H1>
          <P>Neurocoaching ist ein Sammelbegriff für verschiedene Techniken, die das Nervensystem einbeziehen und gezielt darauf einwirken. <br/>Aussagen oder imaginierte Bilder lösen Reaktionen z.B. in Form von Anspannung aus. Stress und stressbeladene Themen können erkannt werden, sogar wenn sie dem/der Klient/in nicht vollständig bewusst sind. Durch gezielte Interventionen können diese Stressreaktionen verändert und eine deutliche Erleichterung im Umgang mit bis dahin bestehenden stressbeladenen Themen erreicht werden.</P>
          <CTA>
           <a href="/neurocoaching"><Button>Mehr</Button></a>
          </CTA>
      </HeroTextSpan>
      <HeroImageSpan>
        <img class="object-cover object-center rounded filter mix-blend-multiply" alt="hero" src={jpgBrain} />
      </HeroImageSpan>
    </Hero>
    <div></div>
  </Section>

  <Section id="supervision">
    <Hero>
      <HeroImageSpan>
        <img class="object-cover object-center rounded" alt="hero" src={jpgSupervision} />
      </HeroImageSpan>
      <HeroTextSpan>
        <H1>Supervision & Teambuilding</H1>
          <P>Haben Sie in einer Gruppe bzw. Ihrem Kollegium eine herausfordernde Situation, deren Klärung und Lösung von innen heraus viel Kraft kostet? Alle Mühen die Situation zu verbessern waren bisher nicht zielführend. Belasten zwischenmenschliche Konflikte das Arbeitsklima?</P>
          <P>Wie beim Autofahren gibt es in (Arbeits-)Beziehungen tote Winkel, die eine klare Sicht auf die mögliche Lösung erschweren bis unmöglich machen. Hier kann es hilfreich sein einen professionellen Blick von außen zu nutzen. Unvoreingenommen und emotional nicht involviert zu sein, macht es leichter die einzelnen Beteiligten und die ganze Gruppe zu unterstützen, Dinge aus einer neuen Perspektive zu sehen. So kann eine tragfähige, gemeinsame Lösung entstehen!</P>
          <P>Brauchen Sie einfach einen Impuls um Dinge in eine gute Richtung zu bringen? Dann kann eine Supervision oder ein Teambuilding das Richtige für Sie sein!</P>
          <P>In einem Vorgespräch schildern Sie mir Ihr Anliegen und den Kontext der aktuellen Unternehmenssituation. Sie und ich ermitteln gemeinsam den Bedarf und definieren ein Ziel für die Supervision.</P>
          <P>Melden Sie sich gerne wenn Sie das Gefühl haben, dass ich für Sie als Impulsgeber und Prozessbegleiter eine gute Unterstützung sein kann!</P>

      </HeroTextSpan>
    </Hero>
  </Section>

  <Section id="naturcoaching" $greyBg>
    <Hero $Right>
      <HeroTextSpan $Right>
        <H1>Natur-Coaching</H1>
          <P>Der Aufenthalt in der Natur ist von elementarer Bedeutung für uns. Die erlebte Verbindung zu unserem Lebensraum mit allen Sinnen ist eine wirksame Medizin um wieder in unsere Mitte zu kommen und unser Gleichgewicht mit uns und der Welt zu finden. Schließlich haben wir als Spezies “Mensch“ entwicklungsgeschichtlich 98% unserer Existenz unmittelbar in der Natur verbracht. Von diesen Wurzeln entfernen wir uns zusehends - zu Lasten unserer Gesundheit und der unseres Lebensraums - der Natur.</P>
          <P>In Zeiten der Pandemie ist ein Treffen in geschlossenen Räumen für viele Menschen mit Sorge verbunden. Ein Coaching an der frischen Luft ist eine überraschend gut funktionierende und sichere Alternative um im Freien den Themen Zuwendung zu schenken, die im Fokus stehen sollen!</P>
          <P>Diese Form des Coaching orientiert sich an den Prinzipien der Natur. In den letzten 20 Jahren habe ich bei verschiedenen indigenen Lehrern in den USA und Europa dieses tiefe Wissen erfahren. Für mich ist die Natur ein Raum um Ruhe, Achtsamkeit und Bewusstsein über sich und seine Umwelt zu gewinnen. In dieser Verbindung können Menschen effektiv Stress reduzieren, Klarheit und Orientierung gewinnen und inneren Frieden finden. Die Natur ist der Reset-Knopf für unser System. Wir verbinden uns in Ihr wieder mit uns Selbst.</P>
        <CTA>
          <Button $Second>Kontakt</Button>
        </CTA>
      </HeroTextSpan>
      <HeroImageSpan >
        <img class="object-cover object-center rounded" alt="hero" src={jpgBaum} />
      </HeroImageSpan>
    </Hero>
  </Section>

  <Parallax bgImage={jpgWald} strength={300} bgImageStyle={{ "object-fit": 'cover'}}>
        <div class="h-screen/2"></div>
  </Parallax>

  <Section id="me"> 
    <Hero $Right>
      <HeroTextSpan $Right>
        <H1>Über <HL>Mich</HL></H1>
          <P><b>Andreas Ochsmann, geb. 1980</b></P>
          <P>Meine Liebe zur Natur hat mich im Alter von 20 Jahren zunächst zu Lehrern verschiedener Naturvölker geführt von denen ich viele Jahre über die Bedeutung von Natur, Kultur, Traditionen - und über mich selbst gelernt habe. Diese langjährige Verbindung und Lehrzeit hat mir geholfen, die Welt mit anderen Augen zu sehen und vieles neu zu entdecken.</P>
          <P>Eine therapeutische Ausbildung und die langjährige Arbeit in dem Bereich hat es mir ermöglicht Menschen in allen Altersgruppen und in unterschiedlichsten Lebenslagen zu begleiten. Durch diese Tätigkeit habe ich viel über existenzielle Schwierigkeiten und Herausforderungen von Menschen und deren Bedeutung als Hürde für ein zufriedenes Leben erfahren und gelernt.</P>
          <P>Bei verschiedenen Lehrern und Ausbildern in Deutschland und den USA habe ich meine Methodenvielfalt Schritt für Schritt erweitert und unterschiedlichste Sichtweisen zur Persönlichkeitsentwicklung kennengelernt: Von systemischem Coaching über Hypnose, körperorientierte Verfahren bis hin zu den Weisheiten von Naturvölkern.</P>
          <P>Als zertifizierter Coach (Leibniz Universität Hannover) kombiniere ich heute verschiedene Methoden, um individuell Veränderung zu unterstützen, die nachhaltig ist. Dadurch wird meine Arbeit immer mehr zu dem, was ich mir unter erfolgreichem Coaching vorstelle: In kurzer Zeit deutlich wahrnehmbare Resultate zu erzielen. Im Kern geht es mir darum Menschen zu ermöglichen, die Person zu werden, die sie wirklich sind.</P>
      </HeroTextSpan>
      <HeroImageSpan>
        <img class="rounded h-screen/2 object-cover object-center " alt="Andreas Ochsmann - Coach"  title="Andreas Ochsmann - Coach" src={jpgPortrait} />
      </HeroImageSpan>
    </Hero>
  </Section>

  <Contactform/>
  <Footer/>
</div>

  );
}

export default Home;


