
import svgLogoBlack from './images/logo_black.svg';
import {Button}  from "./home_style";
import React from 'react';

function Footer() {

  return (
    
<div>
  <footer class="text-gray-600 body-font">
    <div class="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
      <p class="text-sm text-gray-500 sm:py-2 sm:mt-0 mt-4">© 2022 Coaching-AO</p>
      <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
        <a class="text-gray-500 mx-2" href="./impressum">Impressum</a>
        <a class="text-gray-500 mx-2" href="./impressum">Datenschutz</a>
      </span>
    </div>
  </footer>
</div>

  );
}

export default Footer;


