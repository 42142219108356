import tw from "tailwind-styled-components"

export const TwContainer = tw.div`
    flex items-center justify-center flex-col w-full bg-primary-400
`
export const Section = tw.section`
    ${(p) => (p.$Shadow ? "bg-secondary-900 bg-opacity-40 " :  "")}
    ${(p) => (p.$greyBg ? "bg-gray-50" : "")}
    text-gray-600 body-font 
`

export const SectionBody = tw.div`
    container px-5 py-24 mx-auto
`

export const BlogPost = tw.div`
    text-justify w-full mb-12 max-w-screen-xl mx-auto
`

export const Benefits = tw.div`
    flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6
`
export const Benefit = tw.div`
    p-4 md:w-1/2 lg:w-1/3 flex flex-col text-center items-center
`

export const BenefitIcon = tw.div`
    w-20 h-20 inline-flex items-center justify-center rounded-full bg-secondary-400 mb-5 flex-shrink-0 text-white
`

export const Icon10 = tw.img`
    w-10 h-10 text-white
`

export const BenefitText = tw.div`
    flex-grow
`

export const H2 = tw.h2`
    text-gray-900 text-xl title-font font-medium mb-3
`
export const P2 = tw.p`
leading-relaxed text-lg text-justify
`
export const Headline = tw.div`
text-center mb-20
`

export const QuoteBg = tw.div`
bg-cover sm:bg-contain text-center h-48 content-center flex flex-wrap justify-center py-24 mix-blend-multiply -mx-5 px-8 sm:px-0
`


export const QuoteWrapper = tw.div`
    max-w-md
`

export const QuoteText = tw.p`
    leading-relaxed text-basic justify-center
`

export const QuoteName = tw.h2`
    text-gray-900 font-medium title-font tracking-wider text-sm uppercase
`

export const QuoteTitel = tw.div`
    text-gray-500 hidden
`

export const QuoteSeperator = tw.div`
    mb-4
`

export const HL = tw.span`
text-secondary-900
`

export const Blog = tw(SectionBody)``

export const Hero = tw(SectionBody)`
    ${(p) => (p.$Right ? "flex-col-reverse" :  "flex-col")}
    flex md:flex-row items-center md:w-full 2xl:w-2/3
`

export const HeroTextSpan = tw.div`
    ${(p) => (p.$Right ? "lg:pr-24 md:pr-16 " :  "lg:pl-24 md:pl-16 ")}
    lg:flex-grow md:w-1/2  flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center
    
`
export const HeroImageSpan = tw.div`
    lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0
`
export const H1 = tw.h1`
    ${(p) => (p.$Center ? "text-center mx-auto mb-4" :  "")}
    ${(p) => (p.$White ? "text-white" :  "text-gray-800")}
    title-font  sm:text-4xl text-3xl font-medium  pb-8 font-outfit
`
export const P = tw.p`
    ${(p) => (p.$Blog ? "lg:w-2/3 mx-auto" :  "")}
    ${(p) => (p.$White ? "text-white" :  "")}
    pb-6 leading-relaxed text-lg  /*font-plex-serif*/
`

export const CTA = tw.div`
    flex justify-center
`

export const Button = tw.button`
    ${(p) => (p.$Second ? "hover:bg-primary-600  bg-primary-400" :  "hover:bg-secondary-600 bg-secondary-400")}
    ${(p) => (p.$Gap ? "ml-4" :  "")}
    ${(p) => (p.$Small ? "text-base" :  "text-lg")}
    inline-flex  border-0 py-2 px-6 focus:outline-none  rounded-3xl rounded-bl-sm text-white
`

export default TwContainer