import React, { useState } from 'react';

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div class="w-full px-2 py-1">
    <details class="mb-4 ">
        <summary class="px-4 py-2 text-lg  bg-gray-100 rounded-lg cursor-pointer ">
            {title}
        </summary>
        <p class="px-4 py-2">{content}</p>
    </details>    
  </div>

  );
};

export default Accordion;