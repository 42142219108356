export const accordionData = [

    {
      title: 'Achtsamkeitstraining',
      content: `Achtsamkeit ist heute in aller Munde - und das zurecht. Zahlreiche Studien belegen die Wirkung von Achtsamkeitstraining. Es ermächtigt uns beim Umgang mit und bei der Wahrnehmung von Stress, es stärkt unser Immunsystem und dreht unsere biologische Uhr zurück. Achtsames Arbeiten ermöglicht uns im Flow zu sein um unsere Kreativität freizulassen. Vor allem hilft Achtsamkeit uns unser Leben bewusst zu leben und zu erleben. Hier und Jetzt.
      `
    },
    {
      title: 'Focusing',
      content: `Focusing ist eine körperorientierte Methode. Unser Körper ist dabei ein Instrument auf dem verschiedene Töne, die Körpergefühle, erklingen. Diese nutzt Focusing als Ausgangspunkt für Veränderung. Themen brauchen dafür nicht explizit benannt werden. Der eigene Körper wird zum “Reiseleiter“.
      `
    },
    {
      title: 'Hypnose / Hypnosystemisches Coaching',
      content: `Hypnose bedient sich der bildhaften Sprache und Imagination. Mit ihrer Hilfe wird das Unterbewusste des Menschen in Prozesse einbezogen und gewünschte Veränderungen erleichtert. In der Hypnose wird ein Trancezustand erlebt. Dabei ist die Aufmerksamkeit stark reduziert und fokussiert. Das Alltagsbewusstsein tritt in den Hintergrund und es werden Ressourcen freigelegt. Keine Sorge: In der Trance erleben Sie alles ganz bewusst. Sie können sich an alles erinnern und haben in jedem Moment die Kontrolle über den Prozess!  
      `
    },  
    {
      title: 'NLP (Neurolinguistisches Programmieren)',
      content: `Manche Menschen reagieren skeptisch, wenn Sie die drei Buchstaben NLP hören oder lesen. Bedenken bezüglich manipulativen Verwendung sind auch mir bekannt. Ich nutze Methoden aus dem NLP ausschließlich im Sinne Ihres Anliegens und zum Erreichen Ihrer Ziele. Ein von den US Amerikanern Richard Bandler und John Grinder entwickeltes Modell, dass Methoden von drei der einflussreichsten und wegweisenden Therapeuten der 80er Jahre (Milton H. Erickson - Hypnotherapie, Virginia Satir - Systemische (Familien)Therapie und Fritz Perls - Gestalttherapie) vereinfacht, zusammengefasst und weiterentwickelt hat. NLP findet heute Einsatz in vielen unterschiedlichen Bereichen und gilt auch als Kommunikationsmodell durch das über Sprache Bewusstsein in effektiver Weise verändert werden kann.
      `
    },
    {
      title: 'Prozessorientierte Embodimentfokussierte Psychologie (PEP)',
      content: `PEP ist eine wirkungsvolle Methode um Emotionen, hinderliche Glaubenssätze und Beziehungsmuster schnell und wirksam aufzulösen bzw. zu verändern. PEP bezieht den Körper ein und nutzt ihn als “Radarsystem“, um emotionale und kognitive Blockaden fokussiert wahrzunehmen und Veränderungen durch die Anwendung der Methode direkt körperlich zu registrieren, da Emotionen stark mit Körperwahrnehmungen einhergehen. Interessant an der Methode ist auch die Möglichkeit des verdeckten Arbeitens bei dem Anliegen nicht ausgesprochen werden müssen. Das erleichtert die Arbeit bei Themen die z.B. ein Gefühl von Scham auslösen oder über die der Klient aus sonstigen Gründen nicht reden möchte. So bietet PEP eine großartige Möglichkeit die Hürde für solche Themen deutlich herabzusetzen und eine effektive Arbeit zu ermöglichen.
      `
    },
    {
      title: 'Wingwave',
      content: `Wingwave ist ein Konzept für Kurzzeitcoaching, das verschiedene Methoden kombiniert um rasch deutliche Resultate zu erreichen. Wingwave arbeitet mit geführten Augenbewegungen und eignet sich sehr gut für das Lösen von Stressblockaden unterschiedlichster Herkunft, für das Stärken von Ressourcen und der hilfreichen Veränderung von Überzeugungen.  
      `
    }

  ];