
import svgLogoBlack from './images/logo_black.svg';
import {Button}  from "./home_style";
import { useContext } from 'react';
import AppContext from './AppContext';
import React, {useRef,useState,useEffect} from 'react';
import jpgPraxis from './images/praxis_wennigsen.jpg';
import Cookies from 'universal-cookie';
const cookies = new Cookies();



function Contactform() {
  const myContext = useContext(AppContext);
  const [success, setSuccess] = useState(false);

  let cookie2 = cookies.get('rcl_statistics_consent'); // Pacman

  useEffect(() => {
    if ( window.location.search.includes('success=true') ) {
      setSuccess(true);
    }
  }, [myContext.cookie3value]);


  return (
    <section class="text-gray-600 body-font relative" id="kontakt">
    <div class="absolute inset-0 bg-gray-300 pointer-events-none" >
    <div class="h-full overflow-hidden w-full">
      <img class={'object-cover min-h-full border-0 object-center'} src={jpgPraxis} />
    </div> 
    {/*!cookie2 &&
      <div class="h-full overflow-hidden w-full">
      <img class={'object-cover min-h-full border-0 object-center'} src="https://api.mapbox.com/styles/v1/aocoaching/ckw4uhcez0s7a15p1fh3912yq/static/9.5784,52.2695,12.37,0/1280x1280@2x?access_token=pk.eyJ1IjoiYW9jb2FjaGluZyIsImEiOiJja3c0dWY1cWsxcXkzMm5ybzM2ZW9tbzU2In0.snGwfFCdMJGBE3Z2MjLHRA" />
      </div>
    }
    {cookie2 &&
      <iframe class="w-full h-full border-0" src="https://api.mapbox.com/styles/v1/aocoaching/ckw4uhcez0s7a15p1fh3912yq.html?title=false&access_token=pk.eyJ1IjoiYW9jb2FjaGluZyIsImEiOiJja3c0dWY1cWsxcXkzMm5ybzM2ZW9tbzU2In0.snGwfFCdMJGBE3Z2MjLHRA&zoomwheel=false#13.04/52.2755/9.57604" title="Monochrome" ></iframe>
    */}
    </div>
    <form action="/?success=true" name="contact" method="POST" data-netlify="true">
    <div class="container px-5 py-24 mx-auto flex">
      <div class="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md flex-wrap">
        <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">Kontakt</h2>
        <p class="leading-relaxed mb-5 text-gray-600">Melden Sie sich gerne um einen Termin zu machen oder wenn Sie Fragen bezüglich meines Angebots haben!</p>
        <input type="hidden" name="form-name" value="contact" />
        <div class="relative mb-4 w-full xl:w-1/2 xl:pr-2">
          <label for="name1" class="leading-7 text-sm text-gray-600">Vorname</label>
          <input type="text" id="name1" name="name1" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
        </div>
        <div class="relative mb-4 w-full xl:w-1/2 xl:pl-2">
          <label for="name2" class="leading-7 text-sm text-gray-600">Nachname</label>
          <input type="text" id="name2" name="name2" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
        </div> 
        <div class="relative mb-4 w-full">
          <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
          <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
        </div>
        <div class="relative mb-4 w-full">
          <label for="phone" class="leading-7 text-sm text-gray-600">Telefon</label>
          <input type="tel" id="phone" name="phone" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
        </div>

        <div class="relative mb-4 w-full">
          <label for="message" class="leading-7 text-sm text-gray-600">Nachricht</label>
          <textarea id="message" name="message" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
        </div>
        <button class="text-white bg-primary-400 border-0 py-2 px-6 focus:outline-none hover:bg-primary-600 rounded text-lg w-full" type="submit">Senden</button>
        {success && (<p class="text-xs text-gray-500 mt-3">Vielen Dank für Ihre Nachricht!</p>
        )}
      </div>
    </div>
    </form>
  </section>

  );
}

export default Contactform;


