import bgQuote01 from './images/splotch02.jpg';
import bgQuote02 from './images/splotch01.jpg';
import bgQuote03 from './images/splotch03.jpg';
import svgBolt from './images/bolt-solid.svg';
import jpgNeuronen from './images/neuronen.jpg';
import jpgStair from './images/staircase.jpg'

import { Parallax, Background} from 'react-parallax';
import Menu from './menu';
import Accordion from './accordion';
import Footer from './footer';
import Contactform from './contactform';

import { accordionData } from './content/content';
import {HL,Section,SectionBody,Headline,Blog,BlogPost, Hero,HeroTextSpan,H1,P,CTA,Button,HeroImageSpan,Benefits, Benefit, BenefitIcon, Icon10, BenefitText, H2,P2, QuoteBg,QuoteWrapper,QuoteText,QuoteName,QuoteTitel,QuoteSeperator}  from "./home_style";


function Neurocoaching() {


  return (
    
<div>

  <Menu/>

  <Parallax bgImage={jpgNeuronen} strength={300} bgImageStyle={{ "object-fit": 'cover'}}>
      
      <Section $Shadow>
      <div class="h-28 "></div>
      <Hero>
        <HeroTextSpan $Right>
          <H1 $White>Neurocoaching</H1>
          <P $White> Neurocoaching ist ein Sammelbegriff für verschiedene Techniken, die das Nervensystem einbeziehen und gezielt darauf einwirken.​</P>
          <CTA>
            <Button $Second>Methoden</Button>
          </CTA>
        </HeroTextSpan>
        <HeroImageSpan class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
        
        </HeroImageSpan>
      </Hero>
    </Section>
</Parallax>


  <Section>
    <SectionBody>

      <BlogPost class="text-justify w-full mb-12">
        <H1 $Center>Veränderung durch <HL>neue Verbindungen</HL></H1>
        <P $Blog>Die Plastizität unseres Gehirns bietet große Möglichkeiten für Veränderungen. Meist bleiben wir jedoch in gewohnten Bahnen, denken, fühlen und machen das, was “schon immer“ so war. Dabei können wir hindernde Aspekte oft schnell und sogar nachhaltig verändern: Ob Prüfungsangst, der Ekel vor Spinnen, die ungesunde Gewohnheit des Rauchens oder die Nervosität vor dem nächsten Urlaub ins Flugzeug steigen zu müssen: Durch neue Verbindungen entstehen neue Gewohnheiten und alte Verhaltensmuster verlieren ihre Macht. Dabei unterstütze ich Sie gerne mit wissenschaftlich fundierten und wirksamen Methoden!</P>
      </BlogPost>

      <BlogPost class="text-justify w-full mb-12">
        <H1 $Center>Ein neuer Rahmen für das <HL>Selbstbild</HL></H1>
        <P $Blog>Neurocoaching stärkt das Selbstbewusstsein und Selbstvertrauen. Nicht hilfreiche Überzeugungen können verändert werden. Diese Veränderung erweitert das Bewusstsein von sich selbst, erschließt neue Möglichkeiten und zusätzliche Ressourcen. Auf dieser Grundlage können andere Entscheidungen getroffen und neue Wege gegangen werden.</P>
      </BlogPost>

    </SectionBody>
  </Section>

  <Section $greyBg> 
    <SectionBody>

      <BlogPost class="text-justify w-full mb-12">
        <H1 $Center>Den Körper als Detektor nutzen</H1>
        <P $Blog>Emotionen lösen immer ein Körpergefühl aus. Die Redewendungen, dass “eine Last auf einem liegt“, “etwas auf den Magen schlägt“ oder “man sich ausgebremst fühlt“ kommen nicht von ungefähr. Jeder Mensch kennt die erlebte Wechselwirkung von Emotion und Körpergefühl. 
Durch Methoden, die gezielt den Körper und seine Gefühlsveränderungen berücksichtigen, können Zusammenhänge deutlich und Emotionen effektiv verändert werden. </P>
      </BlogPost>

      <BlogPost class="text-justify w-full mb-12">
        <H1 $Center>…auch verdeckt möglich!</H1>
        <P $Blog>Viele Menschen profitieren von der Möglichkeit des "verdeckten Coachings". 
Dabei kann an unangenehmen oder schambesetzten Themen gearbeitet werden, ohne diese direkt zu benennen/anzusprechen. Das funktioniert über Körperreaktionen in Bezug auf Ihre Themen oder Anliegen, die wahrzunehmen ich Sie unterstütze. Sie werden sich dabei in meiner Begleitung sicher und getragen fühlen dürfen.</P>
      </BlogPost>

      <QuoteBg style={{backgroundImage: 'url('+bgQuote01+')',backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
        <QuoteWrapper >
          <QuoteText>»Der Körper ist die Bühne der Gefühle.«</QuoteText>
            <QuoteSeperator ></QuoteSeperator><QuoteName>Antonio Damasio</QuoteName><QuoteTitel>Neurowissenschaftler</QuoteTitel>
        </QuoteWrapper>
      </QuoteBg>

    </SectionBody>
  </Section>

  <Section>
    <SectionBody>

      <BlogPost class="text-justify w-full mb-12">
        <H1 $Center>Die Macht der inneren Bilder</H1>
        <P $Blog>Unser visueller Sinn ist unser dominanter Sinn: Alle anderen Sinne folgen unserem Blick. Auch unser “geistiges Auge“, unsere Vorstellungskraft, übt einen starken Einfluss auf uns aus. Wenn wir gerade “alles schwarz sehen“ ist es enorm hilfreich, dass es wieder hell wird! Im Coaching können störende Bilder aufgelöst werden. Bilder wirken besonders auf unser Unterbewusstsein wo die Kraft der Veränderung wohnt. Allein über unseren Verstand ist nachhaltige Veränderung nicht möglich. Wir alle kennen die Halbwertzeit von guten Vorsätzen…</P>
      </BlogPost>

      <QuoteBg style={{backgroundImage: 'url('+bgQuote03+')',backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
        <QuoteWrapper >
          <QuoteText>»Du wirst nie den Ozean überqueren wenn Du nicht den Mut hast die Küste aus den Augen zu verlieren.«</QuoteText>
            <QuoteSeperator ></QuoteSeperator><QuoteName>Christopher Columbus</QuoteName><QuoteTitel>Seefahrer</QuoteTitel>
        </QuoteWrapper>
      </QuoteBg>
    </SectionBody>
  </Section>

  <Parallax bgImage={jpgStair} strength={300} bgImageStyle={{ "object-fit": 'cover'}}>
    <div class="h-screen/2 "></div>
  </Parallax>

  <Section id="methoden">
    <SectionBody>
      <Headline>
          <H1 $Center><HL>Methoden</HL>, mit denen ich arbeite:</H1>

      </Headline>
      <section class="text-gray-700">
        <div class="container px-5  mx-auto">
            <div class="flex flex-wrap -mx-2 lg:w-4/5 sm:mx-auto sm:mb-2">
            {accordionData.map(({ title, content }) => (
            <Accordion title={title} content={content} />
          ))}
            </div>
        </div>
      </section>

    </SectionBody>
  </Section>

  <Contactform/>
  <Footer/>
</div>





  );
}

export default Neurocoaching;


