
import svgLogoBlack from './images/logo_ao-coaching.svg';
import {Button}  from "./home_style";
import React from 'react';

function Menu() {
  const [isOpen, setisOpen] = React.useState(false);

  function handleClick() {
    setisOpen(!isOpen);
  }

  function closeMenu() {
    setisOpen(false);
  }

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 768;

  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    if(width >= breakpoint) setisOpen(false);
  }, [width]);
  return (
    
<div>
  <header class="text-gray-600 body-font w-screen bg-white ">
    

    <div className={`transition-all duration-100 fixed z-50   ${  isOpen ? "bg-primary-500 text-white h-screen w-3/4 sm:w-1/2" : "bg-white w-screen"}`}>
    <div className={`bg-white fixed h-24    ${  isOpen ? "text-white w-3/4 sm:w-1/2 opacity-100" : "bg-white w-screen opacity-0"}`}></div>
    <div class="relative lg:container lg:mx-auto md:flex p-5 flex-col md:flex-row items-center">
      <div class="overflow-hidden w-32">
      <a class="" href="./">
        <img src={svgLogoBlack} class="h-12 md:h-16"/>
      </a>
      </div>

      <nav class=" md:ml-auto block md:flex flex-wrap items-center text-base" >
        <ul className={`mt-5 mb-6 border-t border-white opacity-0 md:flex ${  isOpen ? "block" : "hidden" } `}></ul>

        <ul className={`lg:ml-2 my-3 md:flex ${  isOpen ? "block" : "hidden" } `}onClick={closeMenu}>
          <a class="mr-6 " href="./neurocoaching">Neuro-Coaching</a>
        </ul>
        <ul className={`lg:ml-2 my-3 md:flex ${  isOpen ? "block" : "hidden" } `}onClick={closeMenu}>
          <a class="mr-6 hover:text-gray-900" href="./#supervision">Supervision</a>
        </ul>
        <ul className={`lg:ml-2 my-3 md:flex ${  isOpen ? "block" : "hidden" } `}onClick={closeMenu}>
          <a class="mr-6 hover:text-gray-900" href="./#naturcoaching">Natur-Coaching</a>
        </ul>
        <ul className={`lg:ml-2 my-3 md:flex ${  isOpen ? "block" : "hidden" } `}>
          <a class="mr-6  hover:text-gray-900" href="./#me" onClick={closeMenu}>Über Mich</a>
        </ul>
        <ul className={`lg:ml-2 my-3 md:flex ${  isOpen ? "block" : "hidden" } `}onClick={closeMenu}>
          <a class="mr-6 hover:text-gray-900" href="./neurocoaching#methoden">Methoden</a>
        </ul>

        {isOpen && (

        <ul className={`ml-2 my-3 md:flex text-sm fixed bottom-6`}>
        Coaching AO <br/> Andreas Ochsmann <br/>Hirtenstr. 7<br/>30974 Wennigsen. <br/>Telefon: 01525 - 38 32 400
        </ul>
        )}
      </nav>
      
      <button  type="button" className="block md:hidden absolute right-10 top-8 text-gray-900" onClick={handleClick}>
          <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              {isOpen && (
              <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
              )}
              {!isOpen && (
              <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
              )}
          </svg>
      </button>
    </div>
    </div>
  </header>
</div>

  );
}

export default Menu;


